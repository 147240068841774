.hero{
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
}

.hero .scroller::-webkit-scrollbar {
    display: none;
}

.hero .scroller{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    touch-action: none;
}

/* .hero .scroller > div:first-child{
    margin-left: 15px;
}

.hero .scroller > div:last-child{
    margin-right: 15px;
} */


.hero .left-arrow,
.hero .right-arrow{
    width: 30px;
    height: 30px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: white;
    color: #224870;
    position: absolute;
    top: 45%;
    cursor: pointer;
    border: 1px solid #BFD9CA
}
.hero .left-arrow{
    left: 0px
}
.hero .right-arrow{
    right: 0px
}

.hero .tracker-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.hero .tracker-wrapper .tracker{
    width: 370px;
    height: 3px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.hero .tracker-wrapper .tracker .tracker-bar{
    margin: 0 15px;
    background-color: gray;
    height: 3px;
}

.hero .tracker-wrapper .tracker .active{
    background-color: white;
}


@media only screen and (max-width: 1024px) {
    .hero .scroller > div:first-child{
        margin-left: 20px;
    }
    
    .hero .scroller > div:last-child{
        margin-right: 20px;
    }
}

@media only screen and (max-width: 640px) {
    .hero .scroller > div:first-child{
        margin-left: 20px;
    }
    
    .hero .scroller > div:last-child{
        margin-right: 20px;
    }

    .buffer > div:first-child{
        margin-left: 20px !important;
    }

    .buffer > div:last-child{
        margin-right: 20px !important;
    }

    .hero .left-arrow{
        left: 10px
    }
    .hero .right-arrow{
        right: 10px
    }
}

@media only screen and (max-width: 315px) {
    .hero .scroller > div:first-child{
        margin-left: 0px;
    }
    
    .hero .scroller > div:last-child{
        margin-right: 0px;
    }

    .buffer > div:first-child{
        margin-left: 20px !important;
    }

    .buffer > div:last-child{
        margin-right: 20px !important;
    }

    .hero .left-arrow{
        display: none;
    }
    .hero .right-arrow{
        display: none;
    }
}