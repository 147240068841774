.loader-wheel {
	border: 6px solid #f3f3f3;
	border-radius: 100%;
	border-top: 6px solid #107048;
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }