@tailwind base;
@tailwind components;
@tailwind utilities;

.toggle-left, 
.toggle-right{
    border-top: 1px solid #79747e;
    border-bottom: 1px solid #79747e;
    padding: 3px 8px;
    width: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;  
    font-size: 11px;
    text-align: center;
}

.toggle-right{
    border-right: 1px solid #79747e;
    border-left: 1px solid #79747e;
    border-radius: 0 100px 100px 0;
}

.toggle-left{
    border-left: 1px solid #79747e;
    border-radius: 100px 0 0 100px;
}

.active{
    background-color: #e3e2e4;
}