.sidebar-width-limiter{
    min-width: 30px;
}

.active-logo {
    border-bottom: solid 2px #F9941E;
}

.passive-logo{
    border-bottom: solid 2px rgba(111,111,111,0);
}

.logo-max-width{
    max-width: 80px;
}