.navbar-image-limiter{
    max-width: 60px !important;
    max-height: 48px !important;
}

.navbar-height{
    height: 65px !important;
}

.active-tab{
    border-bottom: 2px solid #F9941E !important;
    color: white !important;
}

.active-tab-black{
    border-bottom: 2px solid #F9941E !important;
    color: black !important;
}

.inactive-tab{
    border-bottom: 2px solid #46775A !important;
    color: #E6E0E9 !important;
}

.nav-magnified-image{
    z-index: 2000;
    max-width: 80vw;
    max-height: 80vh;
    width: 40vw;
}

.highest-z{
    background-color: #4d4d4d4f;
    z-index: 1999;
}

.priority-1{
    background-color: red;
}

.priority-2{
    background-color: yellow;
}