*:focus {
    outline: none;
}

input:not([disabled]) {
    border: 1px solid #46775A;
    border-radius: 0.375rem;
    padding: 0.3rem 2px 0.3rem 2px;
    background-color: white !important;
  }
  
  select:not([disabled]) {
    border: 1px solid #46775A;
    border-radius: 0.375rem;
    padding: 0.4rem 2px 0.4rem 2px;
    background-color: white !important;
  }
  
  .border-none {
    border: 0 !important;
  }