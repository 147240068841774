@tailwind base;
@tailwind components;
@tailwind utilities;

.ease {
    transition: all 300ms ease;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #EFEFEF;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}


.slider:before {
    position: absolute;
    height: 24px;
    content: "";
    width: 24px;
    left: 4px;
    bottom: 2px;
    background-color: #F6F7F7;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    
}

input:checked+.slider {
    background-color: #71AD89;
}

input:checked+.slider:before {
    background-color: #F6F7F7;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}
.electricity:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-7 -5 25 20'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M4.25 10.0227H0.5L5.75 0V5.72727H9.5L4.25 15.75V10.0227Z' /></svg>");
}

input:checked+.electricity:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-7 -6 25 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M4.25 10.0227H0.5L5.75 0V5.72727H9.5L4.25 15.75V10.0227Z' /></svg>");
}

.water:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-7 -6 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M4.5 0C1.835 2.5025 0.5 4.664 0.5 6.49C0.5 9.229 2.4 11 4.5 11C6.6 11 8.5 9.229 8.5 6.49C8.5 4.664 7.165 2.5025 4.5 0ZM2.415 6.6C2.6 6.6 2.75 6.743 2.785 6.941C2.99 8.162 3.925 8.58 4.605 8.5195C4.82 8.5085 5 8.6955 5 8.932C5 9.152 4.84 9.3335 4.64 9.3445C3.575 9.416 2.33 8.745 2.045 7.0785C2.03631 7.01953 2.03937 6.95919 2.05396 6.90165C2.06856 6.84411 2.09434 6.79074 2.12952 6.74524C2.16471 6.69974 2.20846 6.66318 2.25775 6.63811C2.30704 6.61303 2.36069 6.60003 2.415 6.6Z'/></svg>    ");
}

input:checked+.water:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M4.5 0C1.835 2.5025 0.5 4.664 0.5 6.49C0.5 9.229 2.4 11 4.5 11C6.6 11 8.5 9.229 8.5 6.49C8.5 4.664 7.165 2.5025 4.5 0ZM2.415 6.6C2.6 6.6 2.75 6.743 2.785 6.941C2.99 8.162 3.925 8.58 4.605 8.5195C4.82 8.5085 5 8.6955 5 8.932C5 9.152 4.84 9.3335 4.64 9.3445C3.575 9.416 2.33 8.745 2.045 7.0785C2.03631 7.01953 2.03937 6.95919 2.05396 6.90165C2.06856 6.84411 2.09434 6.79074 2.12952 6.74524C2.16471 6.69974 2.20846 6.66318 2.25775 6.63811C2.30704 6.61303 2.36069 6.60003 2.415 6.6Z'/></svg>    ");
}

.gas:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M7.73996 4.8594C7.60856 4.68162 7.44859 4.52754 7.30004 4.37346C6.91726 4.01789 6.48305 3.76307 6.1174 3.38973C5.26613 2.52452 5.07759 1.09633 5.62035 0C5.07759 0.1363 4.6034 0.444457 4.19776 0.782245C2.71803 2.01487 2.13528 4.18975 2.83229 6.05647C2.85514 6.11573 2.878 6.17499 2.878 6.25203C2.878 6.38241 2.7923 6.50093 2.67803 6.54834C2.54663 6.6076 2.40951 6.57204 2.30096 6.47722C2.26834 6.44925 2.24119 6.41505 2.22097 6.37648C1.57538 5.52905 1.47254 4.3142 1.90675 3.34232C0.952635 4.14827 0.43273 5.51127 0.507003 6.79723C0.541282 7.09354 0.575561 7.38984 0.672687 7.68615C0.752672 8.04171 0.906929 8.39728 1.07833 8.71136C1.69536 9.73658 2.76373 10.4714 3.91209 10.6196C5.13473 10.7796 6.44306 10.5485 7.38003 9.67139C8.42555 8.68766 8.7912 7.11132 8.25416 5.76017L8.17988 5.60609C8.05991 5.33349 7.73996 4.8594 7.73996 4.8594Z'/></svg>");
}

input:checked+.gas:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M7.73996 4.8594C7.60856 4.68162 7.44859 4.52754 7.30004 4.37346C6.91726 4.01789 6.48305 3.76307 6.1174 3.38973C5.26613 2.52452 5.07759 1.09633 5.62035 0C5.07759 0.1363 4.6034 0.444457 4.19776 0.782245C2.71803 2.01487 2.13528 4.18975 2.83229 6.05647C2.85514 6.11573 2.878 6.17499 2.878 6.25203C2.878 6.38241 2.7923 6.50093 2.67803 6.54834C2.54663 6.6076 2.40951 6.57204 2.30096 6.47722C2.26834 6.44925 2.24119 6.41505 2.22097 6.37648C1.57538 5.52905 1.47254 4.3142 1.90675 3.34232C0.952635 4.14827 0.43273 5.51127 0.507003 6.79723C0.541282 7.09354 0.575561 7.38984 0.672687 7.68615C0.752672 8.04171 0.906929 8.39728 1.07833 8.71136C1.69536 9.73658 2.76373 10.4714 3.91209 10.6196C5.13473 10.7796 6.44306 10.5485 7.38003 9.67139C8.42555 8.68766 8.7912 7.11132 8.25416 5.76017L8.17988 5.60609C8.05991 5.33349 7.73996 4.8594 7.73996 4.8594Z'/></svg>");
}

.co:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M5.75 10H1C0.723858 10 0.5 9.77614 0.5 9.5V3.8735C0.5 3.7138 0.677946 3.61851 0.810863 3.70704L3 5.165V3.8735C3 3.7138 3.17795 3.61851 3.31086 3.70704L5.5 5.165V3.8735C5.5 3.7138 5.67795 3.61851 5.81086 3.70704L8 5.165V0.500001C8 0.223858 8.22386 0 8.5 0H10C10.2761 0 10.5 0.223858 10.5 0.5V9.5C10.5 9.77614 10.2761 10 10 10H5.75Z'/></svg>");
}

input:checked+.co:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-5 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M5.75 10H1C0.723858 10 0.5 9.77614 0.5 9.5V3.8735C0.5 3.7138 0.677946 3.61851 0.810863 3.70704L3 5.165V3.8735C3 3.7138 3.17795 3.61851 3.31086 3.70704L5.5 5.165V3.8735C5.5 3.7138 5.67795 3.61851 5.81086 3.70704L8 5.165V0.500001C8 0.223858 8.22386 0 8.5 0H10C10.2761 0 10.5 0.223858 10.5 0.5V9.5C10.5 9.77614 10.2761 10 10 10H5.75Z'/></svg>");
}

.outdoortemp:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M3.5 12C2.87035 12 2.25667 11.8019 1.74587 11.4337C1.23507 11.0656 0.853062 10.546 0.65395 9.94868C0.454838 9.35135 0.448719 8.7065 0.636459 8.1055C0.8242 7.50449 1.19628 6.97779 1.7 6.6V1.8C1.7 1.32261 1.88964 0.864773 2.22721 0.527208C2.56477 0.189642 3.02261 0 3.5 0C3.97739 0 4.43523 0.189642 4.77279 0.527208C5.11036 0.864773 5.3 1.32261 5.3 1.8V6.6C5.80372 6.97779 6.1758 7.50449 6.36354 8.1055C6.55128 8.7065 6.54516 9.35135 6.34605 9.94868C6.14694 10.546 5.76493 11.0656 5.25413 11.4337C4.74333 11.8019 4.12965 12 3.5 12ZM4.1 4.524V1.8C4.10126 1.7227 4.08644 1.64599 4.05649 1.57472C4.02653 1.50346 3.9821 1.43919 3.926 1.386C3.87093 1.32822 3.80489 1.28201 3.73174 1.25007C3.6586 1.21813 3.57981 1.20111 3.5 1.2C3.34087 1.2 3.18826 1.26321 3.07574 1.37574C2.96321 1.48826 2.9 1.64087 2.9 1.8V4.524H4.1Z'/></svg>    ");
}

input:checked+.outdoortemp:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M3.5 12C2.87035 12 2.25667 11.8019 1.74587 11.4337C1.23507 11.0656 0.853062 10.546 0.65395 9.94868C0.454838 9.35135 0.448719 8.7065 0.636459 8.1055C0.8242 7.50449 1.19628 6.97779 1.7 6.6V1.8C1.7 1.32261 1.88964 0.864773 2.22721 0.527208C2.56477 0.189642 3.02261 0 3.5 0C3.97739 0 4.43523 0.189642 4.77279 0.527208C5.11036 0.864773 5.3 1.32261 5.3 1.8V6.6C5.80372 6.97779 6.1758 7.50449 6.36354 8.1055C6.55128 8.7065 6.54516 9.35135 6.34605 9.94868C6.14694 10.546 5.76493 11.0656 5.25413 11.4337C4.74333 11.8019 4.12965 12 3.5 12ZM4.1 4.524V1.8C4.10126 1.7227 4.08644 1.64599 4.05649 1.57472C4.02653 1.50346 3.9821 1.43919 3.926 1.386C3.87093 1.32822 3.80489 1.28201 3.73174 1.25007C3.6586 1.21813 3.57981 1.20111 3.5 1.2C3.34087 1.2 3.18826 1.26321 3.07574 1.37574C2.96321 1.48826 2.9 1.64087 2.9 1.8V4.524H4.1Z'/></svg>    ");
}

.powerfactor:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M6.98571 3.42857C6.87526 3.42857 6.78571 3.33903 6.78571 3.22857V0.571429C6.78571 0.419876 6.72551 0.274531 6.61835 0.167368C6.51118 0.0602039 6.36584 0 6.21429 0C6.06273 0 5.91739 0.0602039 5.81022 0.167368C5.70306 0.274531 5.64286 0.419876 5.64286 0.571429V3.22857C5.64286 3.33903 5.55331 3.42857 5.44286 3.42857H3.55714C3.44669 3.42857 3.35714 3.33903 3.35714 3.22857V0.571429C3.35714 0.419876 3.29694 0.274531 3.18978 0.167368C3.08261 0.0602039 2.93727 0 2.78571 0C2.63416 0 2.48882 0.0602039 2.38165 0.167368C2.27449 0.274531 2.21429 0.419876 2.21429 0.571429V3.22857C2.21429 3.33903 2.12474 3.42857 2.01429 3.42857H0.7C0.589543 3.42857 0.5 3.51811 0.5 3.62857V5.71429C0.50027 6.47062 0.715209 7.21133 1.11983 7.85033C1.50185 8.45363 2.03813 8.94286 2.67217 9.26802C2.74095 9.3033 2.78571 9.37329 2.78571 9.45059V11.2286C2.78571 11.339 2.87526 11.4286 2.98571 11.4286H6.01429C6.12474 11.4286 6.21429 11.339 6.21429 11.2286V9.45057C6.21429 9.37328 6.25903 9.3033 6.3278 9.26801C6.96176 8.94276 7.49799 8.4535 7.87999 7.85022C8.2846 7.21125 8.49959 6.47059 8.5 5.71429V3.62857C8.5 3.51811 8.41046 3.42857 8.3 3.42857H6.98571Z'/></svg>    ");
}

input:checked+.powerfactor:before{
    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='-6 -5 20 18'><path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' d='M6.98571 3.42857C6.87526 3.42857 6.78571 3.33903 6.78571 3.22857V0.571429C6.78571 0.419876 6.72551 0.274531 6.61835 0.167368C6.51118 0.0602039 6.36584 0 6.21429 0C6.06273 0 5.91739 0.0602039 5.81022 0.167368C5.70306 0.274531 5.64286 0.419876 5.64286 0.571429V3.22857C5.64286 3.33903 5.55331 3.42857 5.44286 3.42857H3.55714C3.44669 3.42857 3.35714 3.33903 3.35714 3.22857V0.571429C3.35714 0.419876 3.29694 0.274531 3.18978 0.167368C3.08261 0.0602039 2.93727 0 2.78571 0C2.63416 0 2.48882 0.0602039 2.38165 0.167368C2.27449 0.274531 2.21429 0.419876 2.21429 0.571429V3.22857C2.21429 3.33903 2.12474 3.42857 2.01429 3.42857H0.7C0.589543 3.42857 0.5 3.51811 0.5 3.62857V5.71429C0.50027 6.47062 0.715209 7.21133 1.11983 7.85033C1.50185 8.45363 2.03813 8.94286 2.67217 9.26802C2.74095 9.3033 2.78571 9.37329 2.78571 9.45059V11.2286C2.78571 11.339 2.87526 11.4286 2.98571 11.4286H6.01429C6.12474 11.4286 6.21429 11.339 6.21429 11.2286V9.45057C6.21429 9.37328 6.25903 9.3033 6.3278 9.26801C6.96176 8.94276 7.49799 8.4535 7.87999 7.85022C8.2846 7.21125 8.49959 6.47059 8.5 5.71429V3.62857C8.5 3.51811 8.41046 3.42857 8.3 3.42857H6.98571Z'/></svg>    ");
}

input:focus+.slider {
    box-shadow: 3px 3px 10px #D8D8D8;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 30px;
}

.slider.round:before {
    border-radius: 50%;
}