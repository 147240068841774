* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
   }

   .item {
    padding: 10px;
   }
   
   .item:hover {
    background-color: rgba(44, 141, 247, 0.2);
    cursor: pointer;
   }