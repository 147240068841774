.carousel-category{
    background-color: white;
    margin-right: 22px;
    width: 450px;
    min-width: 450px;
}

.inactive-carousel-card{
    color: gray;
}

.dark-green-text{
    color: #3E5B4A !important;
}

@media only screen and (max-width: 564px) {
    .carousel-category{
        width: 70vw !important;
        max-width: 400px !important;
        min-width: 230px;
    }
}
