.login-background{
    background-color: white !important;
}

.login-background-2{
    background-color: #638971;
}

.login-checkbox{
    color: #444444;
    font-size: 10px;
}

.login-background .greensense-logo{
    width: 400px !important;
}

.login-background .greenwave-logo{
    width: 300px !important;
}