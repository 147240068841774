.date-range-picker-container .hide-calendar-icon {
  font-size: 10px;
  width: 115px;
  padding: 0px !important;
  border: 0 !important;
  margin: 0px !important;
}

.date-range-picker-container .hide-calendar-icon input{
  padding: 0px !important;
  margin: 0px !important;
  color: #107048 !important;
  font-weight: bold !important;
}

input:not([disabled]){
  border: 1px solid #46775A;
  border-radius: 0.375rem;
  padding: 0.3rem 2px 0.3rem 2px;
  background-color: white !important;
}

select:not([disabled]) {
  border: 1px solid #46775A;
  border-radius: 0.375rem;
  padding: 0.4rem 2px 0.4rem 2px;
  background-color: white !important;
}

.border-none{
  border: 0 !important;
}