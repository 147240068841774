@tailwind base;
@tailwind components;
@tailwind utilities;

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import url('https://fonts.googleapis.com');
@import url('https://fonts.gstatic.com');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


* {
  /* border: 1px solid red !important; */
  box-sizing: border-box !important;
}
a, p, h1, h2, h3, h4, h5, h6 {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.open-sans {
  font-family: "Open Sans" !important;
}

.open-sans-bold {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.open-sans-extrabold {
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

#root {
  width: 100%;
  min-height: 100vh;
  position: relative;
}

html,
body,
main {
  width: 100%;
  height: 100vh;

}

main {
  margin-left: 70px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

.input-border {
  border: 1px solid #C1C1C1 !important;
}

.background-white {
  background-color: white !important;
}

.border-light-green {
  border-color: #BFD9C1;
}

.ps-sidebar-root {
  position: absolute !important;
  height: 100%;
}

.dark-green {
  background-color: #385B46 !important;
}

.light-green {
  background-color: #46775A !important;
}

.v-light-green {
  background-color: #75B08E !important;
}

.text-grey {
  color: #7B7884 !important;
}

.text-black {
  color: black !important;
}

.text-white {
  color: white !important;
}

.text-red {
  color: red !important;
}

.text-green {
  color: #346B00 !important;
}

.text-green2 {
  color: #377745 !important;
}

.text-green3 {
  color: #587464 !important;
}

.text-green4 {
  color: #107048 !important;
}

.border-grey {
  border-color: #D2CECE !important;
}

.hyperlink {
  color: #638971;
  font-weight: 600;
}

.login-submit {
  background-image: linear-gradient(#638971, #42644EFC) !important;
}

.card, .side-card {
  box-shadow: 0 2px 15px -3px rgba(0, 0, 0, 0.07), 0 10px 20px -2px rgba(0, 0, 0, 0.04);
  background-color: white;
}

.z1000 {
  z-index: 1000 !important;
}

.input-border {
  border-top: 0 !important;
  border-bottom: 1px solid #D9D9D9 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.submit-button {
  display: flex;
  width: 220px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #107048;
  color: white;
}

.submit-button-small {
  display: flex;
  width: 100px;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: #107048;
  color: white;
}

.hide-input-background {
  background-color: rgb(243, 243, 243) !important;
}

.hide-input-background:disabled {
  background-color: transparent !important;
}

.transparent-background{
  background-color: transparent !important;
}

.error-border {
  border: 1px solid red !important;
}

.active-user{
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: #107048;
  margin: 8px;
}

.inactive-user{
  border-radius: 100%;
  width: 12px;
  height: 12px;
  background-color: #ffc02e;
  margin: 8px;
}

.modal-background{
  background-color: rgba(207, 205, 205, 0.213);
  z-index: 1000;
}


@media only screen and (min-width: 1024px) {
  .side-card {
    box-shadow: none;
    background-color: white;
  }
}