.main-display{
    height: 20vw;
    min-height: 300px;
}

.image-selector{
    width: 5vw;
    min-width: 66px;
    max-width: 90px;
    height: 5vw;
    min-height: 66px;
    max-height: 90px;
}

@media only screen and (max-width: 640px) {
    .main-display{
        min-width: 0;
    }
}